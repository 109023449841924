import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { PUBLIC_SHOP, PUBLIC_SHOP_PAYMENT, ADEPT_DASHBOARD } from 'Consts/routes';
import { API_RESOURCE_REDIRECT_URI, API_RESOURCE_OFFER } from 'Consts/apiResources';
import { 
    OFFER_SHOP_CONFIGURATOR_OFFLINE_METAMORPHOSIS,
    OFFER_SHOP_CONFIGUTATOR_OFFLINE_SINGLE,
} from 'Consts/offers';
import { PRODUCT_CATEGORY_SHOP_SLUG_OFFLINE } from 'Consts/products';

import Logger from 'Services/Logger';

import { withVariables } from 'Utils/string';
import { parseQueryToObject, parseToQueryString } from 'Utils/querystring';
import { 
    getUsersCountButtonVisibility, 
    getOfferType, 
    getTypeOptions, 
    getFrequencies, 
    getResults, 
    getAvailablePaymentTypes, 
    getPricing,
} from 'Utils/shopPage';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Spinner from 'Components/layout/Spinner';
import StepProgress from 'Components/layout/StepProgress';
import UserForm from 'Components/public/shop/UserForm';
import PaymentTypes from 'Components/public/shop/PaymentTypes';
import PaymentCheckout from 'Components/public/shop/PaymentCheckout';
import PaymentButton from 'Components/public/shop/PaymentCheckoutPaymentButton';
import OfferCreator from 'Components/public/shop/OfferCreator';
import Button from 'Components/layout/Button';
import OfferPackage from 'Components/public/shop/OfferPackage';
import OfferTrainingsNumber from 'Components/public/shop/OfferTrainingsNumber';
import OfferPricing from 'Components/public/shop/OfferPricing';
import MigrationWrapper from 'Components/public/shop/MigrationWrapper';
import DuplicatedUserOfferWrapper from 'Components/public/shop/DuplicatedUserOfferWrapper';
import ModalUsersCount from 'Components/public/modals/UsersCount';

export default class PublicShopStudio extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        authToken: PropTypes.string,
        profile: PropTypes.object,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            getProfile: PropTypes.func.isRequired,
            getSingleOfferBySlug: PropTypes.func.isRequired,
        }).isRequired,
        userOffer: PropTypes.object,
    };
    static defaultProps = {
        authToken: null,
        profile: null,
        userOffer: null,
    };
    state = {
        currentStep: 0,
        offers: [],
        selectedDurationValue: null,
        selectedOfferType: null,
        selectedFrequencyMonth: null,
        promoCode: null,
        selectedUsersCount: 1,
        isModalUsersCountVisible: false,
        paymentType: null,
        paymentMethod: null,
    }
    
    componentDidMount = () => {
        const { authToken, actions } = this.props;

        if (authToken) {
            actions.getProfile();
        }

        this.loadOffers().then(() => this.selectFromQueryObject());
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { userOffer, location, history } = this.props;
        const { currentStep, selectedDurationValue, selectedOfferType, selectedFrequencyMonth } = this.state;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        if (userOffer && JSON.stringify(userOffer) !== JSON.stringify(prevProps.userOffer)) {
            this.setState({ promoCode: userOffer.data?.promoCode });
        }

        if (prevState.currentStep !== currentStep) {
            window.scrollTo(0, 0);
        }

        if (selectedDurationValue && selectedDurationValue !== prevState.selectedDurationValue) {
            history.push(parseToQueryString(location.pathname, { ...queryObject, durationValue: selectedDurationValue }));
        }

        if (selectedOfferType && selectedOfferType !== prevState.selectedOfferType) {
            history.push(parseToQueryString(location.pathname, { ...queryObject, offerType: selectedOfferType }));
        }

        if (selectedFrequencyMonth && selectedFrequencyMonth !== prevState.selectedFrequencyMonth) {
            history.push(parseToQueryString(location.pathname, { ...queryObject, frequency: selectedFrequencyMonth }));
        }
    }

    loadOffers = () => {
        const { actions } = this.props;

        return Promise.all([
            actions.getSingleOfferBySlug({ slug: OFFER_SHOP_CONFIGUTATOR_OFFLINE_SINGLE }),
            actions.getSingleOfferBySlug({ slug: OFFER_SHOP_CONFIGURATOR_OFFLINE_METAMORPHOSIS }),
        ]).then(responses => {
            let offers = responses.map(response => response.payload[API_RESOURCE_OFFER]);
            offers = offers.map(offer => ({
                ...offer,
                offerType: getOfferType(offer.periodType, offer.noticePeriodDurationValue)?.value || null,
                offerTypeOption: getOfferType(offer.periodType, offer.noticePeriodDurationValue) || null,
            }));

            this.setState({ offers });
        });
    }

    selectFromQueryObject = () => {
        const { location } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        let nextState = {};
        if(queryObject.durationValue) {
            const durationValue = parseInt(queryObject.durationValue);
            if(!isNaN(durationValue)) {
                nextState.selectedDurationValue = durationValue;
                nextState.currentStep = 1;
            }
        } 

        if(queryObject.offerType) {
            nextState.selectedOfferType = queryObject.offerType;
            nextState.currentStep = 2;
        } 

        if(queryObject.frequency) {
            const frequency = parseInt(queryObject.frequency);
            if(!isNaN(frequency)) {
                nextState.selectedFrequencyMonth = frequency;
                nextState.currentStep = 2;
            }
        } 

        this.setState({ ...nextState });
    }

    onSubmit = ({ userProductId, product, offer }) => {
        const { history, actions, location } = this.props;
        const { selectedFrequencyMonth, promoCode, paymentMethod, paymentType, selectedUsersCount } = this.state;
        const queryObject = parseQueryToObject(location.search);

        this.setState({
            isPending: true,
            globalError: null,
        }, () => {
            actions.create({
                frequencyMonth: selectedFrequencyMonth,
                productId: product.id,
                userProductId,
                offerId: offer.id,
                transactionType: paymentType,
                paymentMethodId: paymentMethod?.id,
                promoCodeId: promoCode?.id,
                continueUrl: process.env.APP_URL + withVariables(PUBLIC_SHOP_PAYMENT.path),
                userOfferId: queryObject.userOfferId || undefined,
                usersCount: selectedUsersCount,
                comment: queryObject.comment || undefined,
            })
                .then(response => {
                    const data = response.payload;
                    if (data[API_RESOURCE_REDIRECT_URI]) {
                        window.location.href = data[API_RESOURCE_REDIRECT_URI];
                    } else {
                        toast('Zamówienie zostało utworzone');

                        history.push(ADEPT_DASHBOARD.path);
                    }
                })
                .catch(error => {
                    toast('Nie udało się utworzyć zamówienia. Zweryfikuj wprowadzone informacje i spróbuj ponownie');
                    Logger.error('[SHOP] CreateOrder Error', { error, product, selectedFrequencyMonth, promoCode, paymentMethod, paymentType });
                    this.setState({
                        isPending: false,
                        errors: error.payload.validationErrors,
                        globalError: error.payload.message,
                    });
                });
        });
    }

    onAddPromoCode = (data) => {
        if(data) {
            const { promoCode } = data;
            this.setState({ promoCode });
        } else {
            this.setState({ promoCode: null });
        }
    }

    showUsersCountModal = () => {
        this.setState({ isModalUsersCountVisible: true });
    }

    render() {
        const { location, history, profile } = this.props;
        const { 
            paymentType,
            paymentMethod,
            promoCode,
            isPending,
            offers,
            selectedDurationValue,
            selectedOfferType,
            selectedFrequencyMonth,
            selectedUsersCount,
            isModalUsersCountVisible,
        } = this.state;
        let { currentStep } = this.state;

        const queryObject = parseQueryToObject(location.search);

        const steps = [{
            label: 'Oferta',
            key: 'offer',
            onClick: () => this.setState({ currentStep: 0 }),
        },  {
            label: 'Wybierz pakiet',
            key: 'offerPackage',
            onClick: () => this.setState({ currentStep: 1 }),
        }, {
            label: 'Wybierz ilość treningów w miesiącu',
            key: 'offerTrainingsNumber',
            onClick: () => this.setState({ currentStep: 2 }),
        }, {
            label: 'Twoje dane',
            key: 'user',
            onClick: () => this.setState({ currentStep: 4 }),
        }, {
            label: 'Płatność',
            key: 'payment',
            onClick: () => profile && this.setState({ currentStep: 5 }),
        }];

        const durationValueOffers = offers
            .filter((offer) => Boolean(
                offer.products.find(offerProduct => {
                    return offerProduct?.product?.durationValue === selectedDurationValue;
                })
            ));
        const offerTypeOffers = durationValueOffers
            .filter((offer) => offer.offerType === selectedOfferType);

        const frequencyOffers = offerTypeOffers.filter((offer) => Boolean(
            offer.products.find(offerProduct => {
                return offerProduct?.product?.pricingMatrix.find(pricingMatrixElem => parseInt(pricingMatrixElem.quantity) === selectedFrequencyMonth);
            })
        ));

        const typeOptions = getTypeOptions(durationValueOffers);
        const frequencies = getFrequencies(offerTypeOffers);

        let product = null;
        let offer = null;
        if(frequencyOffers.length > 0) {
            const results = getResults(frequencyOffers, selectedDurationValue);
            product = results?.product;
            offer = results?.offer;
        }

        const availablePaymentTypes = getAvailablePaymentTypes(offer);
        const { pricing, totalPrice, discountPricing, totalDiscountPrice, userProductId } = getPricing(product, selectedFrequencyMonth, selectedUsersCount);

        return (
            <StyledComponent
                className="public-shop-studio"
                styles={require('./styles')}
            >
                <Head title="Sklep" />
                <MigrationWrapper
                    location={location}
                    history={history}
                    productCategory={PRODUCT_CATEGORY_SHOP_SLUG_OFFLINE}
                >
                    {({ userOffer }) => {
                        return (
                            <LayoutContainer size="s">
                                <div className="progress-container">
                                    <StepProgress
                                        showControls={false}
                                        currentStep={currentStep}
                                        steps={steps}
                                        type='simple'
                                    />
                                    <h1 className="progress-title">
                                        Skonfiguruj swój pakiet treningów w studiu treningowym FitAdept (Warszawa, pl. Zawiszy 1, hotel Radisson Sobieski)
                                    </h1>
                                </div>
                                <div 
                                    className={classnames({
                                        'content-container': true,
                                        'no-padding': Boolean(userOffer),
                                    })}
                                >
                                    {offers.length === 0 && (
                                        <Spinner text="Pobieramy najlepsze oferty" />
                                    )}
                                    {queryObject.userOfferId && userOffer && (
                                        <div className="header">
                                            <h2 className="headline">
                                                Aktualnie zmieniasz zamówienie {userOffer.data?.name}
                                            </h2>
                                        </div>
                                    )}
                                    {offers.length > 0 && (
                                        <>
                                            {steps[currentStep].key === 'offer' && (
                                                <OfferCreator
                                                    offers={offers}
                                                    title="Wybierz długość treningu"
                                                    selectedDurationValue={selectedDurationValue}
                                                    onSelectDurationValue={nextDurationValue => {
                                                        this.setState({ 
                                                            selectedDurationValue: nextDurationValue,
                                                            currentStep: currentStep + 1,
                                                        });
                                                    }}
                                                />
                                            )}

                                            {steps[currentStep].key === 'offerPackage' && selectedDurationValue && (
                                                <OfferPackage
                                                    title="Wybierz pakiet"
                                                    typeOptions={typeOptions}
                                                    selectedOfferType={selectedOfferType}
                                                    onSelectOfferType={(nextOfferType) => {
                                                        this.setState({
                                                            selectedOfferType: nextOfferType,
                                                            currentStep: currentStep + 1,
                                                        });
                                                    }}
                                                />
                                            )}

                                            {steps[currentStep].key === 'offerTrainingsNumber' && selectedOfferType && (
                                                <OfferTrainingsNumber
                                                    frequencies={frequencies}
                                                    selectedFrequencyMonth={selectedFrequencyMonth}
                                                    onSelectFrequencyMonth={selectedFrequencyMonth => {
                                                        this.setState({
                                                            selectedFrequencyMonth,
                                                        });
                                                    }}
                                                />
                                            )}
                                            {steps[currentStep].key === 'offerTrainingsNumber' && getUsersCountButtonVisibility(product, selectedFrequencyMonth) && (
                                                <div className="choose-users-count-button">
                                                    <a
                                                        className="users-count-link"
                                                        onClick={this.showUsersCountModal}
                                                    >
                                                        Chcesz trenować ze znajomymi? Kliknij tutaj
                                                    </a>
                                                </div>
                                            )}
                                            {steps[currentStep].key === 'user' && (
                                                <UserForm
                                                    history={history}
                                                    shopType="elasticStudio"
                                                    onComplete={() => this.setState({ currentStep: currentStep + 1 })}
                                                />
                                            )}

                                            {steps[currentStep].key === 'payment' && Boolean(profile) && (
                                                <DuplicatedUserOfferWrapper
                                                    location={location}
                                                    history={history}
                                                    productCategory={PRODUCT_CATEGORY_SHOP_SLUG_OFFLINE}
                                                >
                                                    <PaymentCheckout 
                                                        location={location}
                                                        history={history}
                                                        paymentType={paymentType}
                                                        paymentMethod={paymentMethod}
                                                        promoCode={promoCode}
                                                        onAddPromoCode={this.onAddPromoCode}
                                                        onSubmit={() => this.onSubmit({ userProductId, offer, product })}
                                                        isPending={isPending}
                                                        showPaymentButton={false}
                                                        data={{
                                                            frequencyMonth: selectedFrequencyMonth,
                                                            periodType: offer?.periodType || undefined,
                                                            totalPriceGross: totalPrice,
                                                            totalDiscountPriceGross: totalDiscountPrice,
                                                            userProductId,
                                                            userOfferId: queryObject.userOfferId || undefined,
                                                        }}
                                                    />

                                                    <PaymentTypes 
                                                        location={location}
                                                        history={history}
                                                        availablePaymentTypes={availablePaymentTypes}
                                                        paymentType={paymentType}
                                                        paymentMethod={paymentMethod}
                                                        onSelectPaymentType={paymentType => this.setState({ paymentType })}
                                                        onSelectPaymentMethod={paymentMethod => this.setState({ paymentMethod })}
                                                    />

                                                    <PaymentButton 
                                                        location={location}
                                                        history={history}
                                                        paymentType={paymentType}
                                                        paymentMethod={paymentMethod}
                                                        onSubmit={() => this.onSubmit({ userProductId, offer, product })}
                                                        isPending={isPending}
                                                        showConsents={false}
                                                        style={"newPage"}
                                                    />
                                                </DuplicatedUserOfferWrapper>
                                            )}
                                            {currentStep > 1 && currentStep < 3 && selectedFrequencyMonth && (
                                                <OfferPricing
                                                    totalPrice={totalPrice}
                                                    unitPrice={pricing?.valueGross}
                                                    totalDiscountPrice={totalDiscountPrice}
                                                    unitDiscountPrice={discountPricing?.valueGross}
                                                    offer={offer}
                                                    frequency={selectedFrequencyMonth}
                                                    onComplete={() => this.setState({ currentStep: currentStep + 1 })}
                                                    selectedUsersCount={selectedUsersCount}
                                                />
                                            )}
                                            <div className="footer">
                                                {currentStep === 0 && (
                                                    <Button
                                                        onClick={() => history.push(withVariables(PUBLIC_SHOP.path))}
                                                        style='newPage'
                                                    >
                                                        Cofnij
                                                    </Button>
                                                )}

                                                {currentStep > 0 && (
                                                    <Button
                                                        onClick={() => this.setState({ 
                                                            currentStep: 0,
                                                            promoCode: 0, 
                                                            selectedDurationValue: null,
                                                            selectedOfferType: null,
                                                            selectedFrequencyMonth: null,
                                                        })}
                                                        style='newPage'
                                                    >
                                                        Cofnij
                                                    </Button>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <ModalUsersCount
                                    location={location}
                                    history={history}
                                    isOpen={isModalUsersCountVisible}
                                    onClose={() => this.setState({ isModalUsersCountVisible: false })}
                                    onSuccess={() => this.setState({ isModalUsersCountVisible: false })}
                                    selectedProduct={product}
                                    selectedUsersCount={selectedUsersCount}
                                    onSelectUsersCount={selectedUsersCount => {
                                        this.setState({
                                            selectedUsersCount,
                                            isModalUsersCountVisible: false,
                                        });
                                    }}
                                    selectedFrequencyMonth={selectedFrequencyMonth}
                                />
                            </LayoutContainer>
                        );
                    }}
                </MigrationWrapper>
            </StyledComponent>
        );
    }
}
